import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/home/Home.js";
import LogoutPage from "./pages/checkout/Logout.js";
import LoginPage from "./pages/checkin/Login.js";
import SelfiePage from "./pages/selfie/Selfie.js";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/checkin" element={<LoginPage />} />
        <Route path="/checkout" element={<LogoutPage />} />
        <Route path="/selfie" element={<SelfiePage />} />
      </Routes>
    </Router>
  );
}

export default App;
