import React from "react";
import axios from "axios";
import "./Logout.css";
import SelfiePage from "../selfie/Selfie.js";

function LogoutPage() {
  const login = async (email) => {
    try {
      const response = await axios.post(
        "https://ego2save.purplesea-60d4b659.eastus.azurecontainerapps.io/api/patient/sign-in",
        {
          email: email,
          password: "Ego1970@",
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const receivedToken = response.data.token;

      localStorage.setItem("authToken", receivedToken);

      return receivedToken;
    } catch (error) {
      console.error("Erro ao fazer login:", error);
      return null;
    }
  };

  return (
    <div>
      <SelfiePage
        login={login}
        showEmailInput={true}
        text="Envie sua selfie para fazer checkout"
        messageSuccess="Check-out realizado com sucesso!"
      />
    </div>
  );
}

export default LogoutPage;
