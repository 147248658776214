import { React, useState, useRef } from "react";
import MessageSuccess from "../../components/messageSuccess/MessageSuccess.js";
import Webcam from "react-webcam";
import axios from "axios";
import "./Selfie.css";

function SelfiePage({
  text = "Envie sua selfie para fazer check-in",
  messageSuccess = "Check-in realizado com sucesso!",
  showEmailInput = false,
  login,
}) {
  const token = localStorage.getItem("authToken");
  const webcamRef = useRef(null);
  const [photoTaken, setPhotoTaken] = useState(false);
  const [email, setEmail] = useState("");
  const [image, setImage] = useState(null);
  const [checkinSuccess, setCheckinSuccess] = useState(false);

  const capture = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    setPhotoTaken(true);
  };

  const handleSend = async () => {
    let getToken = localStorage.getItem("authToken");

    if (showEmailInput && !!token) {
      getToken = await login(email);
      if (!token) {
        console.error("Erro ao obter token de autenticação.");
        return;
      }
    }

    await sendSelfie(getToken);
  };
  const sendSelfie = async (token) => {
    if (image) {
      try {
        if (token) {
          const blob = await (await fetch(image)).blob();
          const file = new Blob([blob], { type: "image/jpeg" });

          const formData = new FormData();
          formData.append("file", file, "selfie.jpg");
          formData.append("mimetype", "image/jpeg");
          formData.append("size", file.size.toString());

          await axios.put(
            "https://ego2save.purplesea-60d4b659.eastus.azurecontainerapps.io/api/patient/selfie?isWeb=false",
            formData,
            {
              headers: {
                Authorization: `${token}`,
                "Content-Type": "multipart/form-data",
              },
            }
          );

          setCheckinSuccess(true);
        } else {
          console.error("Token não encontrado.");
        }
      } catch (error) {
        console.error("Erro ao enviar selfie:", error);
      }
    } else {
      console.log("Nenhuma imagem capturada.");
    }
  };

  const resetCamera = () => {
    setImage(null);
    setPhotoTaken(false);
    setCheckinSuccess(false);
  };

  return (
    <>
      {checkinSuccess ? (
        <MessageSuccess message={messageSuccess} />
      ) : (
        <div className="ipad-pro">
          <div className="container_ipad-pro">
            <p className="text-wrapper">{text}</p>
            {showEmailInput && (
              <div className="email-input-container">
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Digite seu e-mail"
                  className="email-input"
                />
              </div>
            )}

            <div className="camera-container">
              {!image ? (
                <Webcam
                  audio={false}
                  ref={webcamRef}
                  className="webcam"
                  screenshotFormat="image/jpeg"
                  width={320}
                  height={240}
                  videoConstraints={{
                    facingMode: "user",
                  }}
                  style={{
                    transform: "scaleX(-1)",
                  }}
                />
              ) : (
                <img src={image} alt="selfie" className="preview-selfie" />
              )}
            </div>

            <div className="container_buttons">
              {!photoTaken ? (
                <button onClick={capture} className="button_ipad-pro">
                  <div className="button-text">Capturar Selfie</div>
                </button>
              ) : (
                <>
                  <button
                    onClick={handleSend}
                    className="button_ipad-pro button-active"
                  >
                    <div className="button-text">Enviar</div>
                  </button>
                  <button
                    onClick={resetCamera}
                    className="button_ipad-pro button-secondary"
                  >
                    <div className="button-text">Tirar uma Nova Selfie</div>
                  </button>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default SelfiePage;
